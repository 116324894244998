<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/deviceList' }">设备清单</el-breadcrumb-item>
          <el-breadcrumb-item>设备详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" ref="ruleForm" label-width="100px">
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="设备名称：" prop="name">{{ form.archivesName }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="物料编码：" prop="name">{{ form.materialsCode }}</el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="设备型号：" prop="name">{{ form.model }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="规格参数：" prop="name">{{ form.specification }}</el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="生产厂家：" prop="name">{{ form.factory }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="数量：" prop="name">{{ form.count }}</el-form-item>
            </el-col>
          </el-row>
          <div class="form-title">管理信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="所属部门：" prop="name">{{ form.departmentName }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="负责人：" prop="name">{{ form.staffName }}</el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="设备分类：" prop="name">{{ form.typeName }}</el-form-item>
            </el-col>
          </el-row>

          <div class="form-title">设备图片</div>
          <div class="img-box">
            <el-image 
              class="images"
              v-for="item in form.deviceArchivesImages"
              :key="item.url"
              :src="item.url"
              :preview-src-list="[item.url]">
            </el-image>
            <!-- <img v-for="item in form.deviceArchivesImages" :key="item.id" :src="item.url" /> -->
          </div>
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/deviceList')">取消</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      row: {},
      loading: false
    };
  },
  methods: {
    loadData() {
      this.loading = true
      this.$ajax.post("deviceDetail", {
        id: this.row.id,
      }).then((res) => {
        sessionStorage.setItem("detail", true);
        this.form = res.data;
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
  },
  mounted() {
    if (this.$route.params.row == null) {
			this.$router.push('/deviceList')
		} else {
      this.row = this.$route.params.row
    }
    this.loadData()
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.img-box {
  .images {
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    margin: 0 8px 8px 0;
    display: inline-block;
  }
}
</style>
<style lang="less">
</style>
